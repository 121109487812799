// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/styles/material-theme.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/styles/material-theme.tsx");
  import.meta.hot.lastModified = "1741664317000";
}
// REMIX HMR END

import { createTheme, ThemeProvider } from "@mui/material";
import { ErrorOutline, InfoOutlined, TaskAltOutlined, WarningAmber } from '@mui/icons-material';
const MaterialThemeProvider = ({
  children
}) => {
  const fontType = "Inter, sans-serif";
  const materialUiTheme = createTheme({
    typography: {
      fontFamily: fontType
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            height: '48px',
            alignItems: 'center'
          },
          action: {
            paddingTop: '0px'
          }
        },
        defaultProps: {
          iconMapping: {
            warning: <WarningAmber className="!text-white"></WarningAmber>,
            success: <TaskAltOutlined className="!text-white"></TaskAltOutlined>,
            error: <ErrorOutline className="!text-white"></ErrorOutline>,
            info: <InfoOutlined className="!text-white"></InfoOutlined>
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '30px',
            color: '#2B406A'
          }
        }
      },
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: '30px',
            color: '#2B406A'
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            color: '#2B406A',
            fontSize: '1.875rem',
            fontWeight: 'normal',
            fontFamily: fontType
          },
          h4: {
            color: '#2B406A',
            fontSize: '1.625rem',
            fontWeight: '700',
            fontFamily: fontType
          },
          h5: {
            color: '#2B406A',
            fontSize: '1.125rem',
            fontWeight: 'normal',
            fontFamily: fontType
          },
          h6: {
            color: '#2B406A',
            fontSize: '1rem',
            fontWeight: 'normal',
            fontFamily: fontType
          },
          body1: {
            color: '#1F1F1F',
            fontSize: '0.9rem',
            fontWeight: 'normal',
            fontFamily: fontType
          },
          body2: {
            color: '#1F1F1F',
            fontSize: '1rem',
            fontWeight: 'normal',
            fontFamily: fontType
          }
        }
      }
    }
  });
  return <ThemeProvider theme={materialUiTheme}>
    {children}
  </ThemeProvider>;
};
_c = MaterialThemeProvider;
export default MaterialThemeProvider;
var _c;
$RefreshReg$(_c, "MaterialThemeProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;